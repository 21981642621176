<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card class="br-16">
      <v-card-title
        class="primary display-2 white--text font-weight-bold text-left pb-4"
        >Picking list</v-card-title
      >
      <v-card-text>
        <p>List of products from all orders with status <b>Processing</b></p>
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">Qty</th>
                <th class="text-left">Item</th>
                <th class="text-left">Orders</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v, k) in prod_name__prod_data" :key="k">
                <td class="text-center">{{ add_quantities(v.quantities) }}</td>
                <td class="text-left">{{ v.item }}</td>
                <td class="text-left">{{ v.orders.join(", ") }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-divider />
      <v-card-actions class="d-print-none justify-end">
        <v-btn color="black" text rounded @click="show = false">Close</v-btn>
        <v-btn
          color="primary"
          elevation="0"
          rounded
          @click="print_picking_list()"
          >Print</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PickingList",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("AdminStore", ["orders"]),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    prod_name__prod_data() {
      if (!this.orders) return;

      let prod_name__prod_data = new Map();

      this.orders.forEach((order) => {
        // Ignore all orders with status other than "PROCESSING"
        if (order.status !== "processing") return;
        // Loop through products array in order
        // Add product if Map hasn't got it, else update existing product data
        else {
          const products = order.products || [];
          products.forEach((product) => {
            if (!prod_name__prod_data.has(product.name)) {
              prod_name__prod_data.set(product.name, {
                orders: [`${order.order_no} (${product.quantity})`],
                item: product.name,
                quantities: [product.quantity],
                // orders: [order.id],
              });
            } else {
              prod_name__prod_data
                .get(product.name)
                .quantities.push(product.quantity);
              prod_name__prod_data
                .get(product.name)
                .orders.push(`${order.order_no} (${product.quantity})`);
            }
          });
        }
      });
      return [...prod_name__prod_data.values()];
    },
  },
  methods: {
    add_quantities(prod_quantities) {
      const reducer = (acc, curr) => acc + curr;
      return prod_quantities.reduce(reducer);
    },
    print_picking_list() {
      window.print();
    },
  },
};
</script>
