<template>
  <v-btn
    :loading="loading"
    :disabled="loading"
    @click="generate_csv"
    rounded
    color="primary"
    v-bind="$attrs"
  >
    Download CSV</v-btn
  >
</template>

<script>
import moment from "moment";

export default {
  name: "DownloadCSV",
  props: {
    source: {
      type: Array,
    },
    headers: {
      type: Object,
    },
    file_name: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    convert_to_csv(data) {
      var array = typeof data != "object" ? JSON.parse(data) : data;
      let str = "";

      for (let i = 0; i < array.length; i++) {
        let line = "";
        for (let index in array[i]) {
          if (line != "") line += ",";

          line += `"${array[i][index]}"`;
        }

        str += line + "\r\n";
      }

      return str;
    },

    export_csv_file(headers, items, fileName) {
      if (headers) {
        items.unshift(headers);
      }

      let jsonObject = JSON.stringify(items);

      let csv = this.convert_to_csv(jsonObject);

      let csv_name =
        fileName + `_${moment().format("DD-MM-YY_HH-mm_a")}` + ".csv" ||
        "export.csv";

      let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, csv_name);
      } else {
        let link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", csv_name);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      items.shift(headers);
    },
    async generate_csv() {
      this.loading = true;
      await this.export_csv_file(this.headers, this.source, this.file_name);
      this.loading = false;
    },
  },
};
</script>
