<template>
  <v-dialog v-model="show" class="br-16" persistent max-width="550px">
    <v-card class="br-16">
      <v-card-title class="primary font-weight-bold pb-4 text-left white--text"
        >Update Order Status</v-card-title
      >
      <v-card-text>
        <v-alert
          type="warning"
          outlined
          color="red"
          v-if="updating_claimed_collab_order"
        >
          Some selected orders are claimed in Collaboration. It's only possible
          to change it status by the claimer completing the route.
        </v-alert>
        <v-alert
          type="info"
          outlined
          color="purple"
          v-else-if="updating_open_collab_order"
        >
          Please note some orders are Collaboration orders. By changing their
          status they will be removed from the collaboration pool.
        </v-alert>
        <v-alert
          type="info"
          outlined
          color="black"
          v-else-if="updating_shared_order"
        >
          Some selected orders are managed by different shop. It's only possible
          to change it status by the owner of that shop.
        </v-alert>
        <div v-if="!updating_claimed_collab_order && !updating_shared_order">
          <v-radio-group
            v-model="selected_status"
            v-for="(value, status_id) in ORDER_STATUSES"
            :key="status_id"
            column
            class="ma-0 pa-0 statuses"
          >
            <v-radio class="font-weight-bold" :value="status_id"
              ><template #label>
                <span
                  style="height: 20px; width: 20px; border-radius: 50%"
                  :class="['mr-2', status_id]"
                />{{ value.name }}</template
              ></v-radio
            >
          </v-radio-group>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions class="justify-end pr-4 pb-4">
        <v-btn color="black" rounded text @click="clear()">Close</v-btn>
        <v-btn
          :disabled="!selected_status || loading"
          color="primary"
          rounded
          @click="update_orders()"
          class="mr-0"
        >
          <span v-if="!loading">Update</span>
          <v-progress-circular
            v-else
            size="10"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { ORDER_STATUSES } from "../dashboard_data";
import { update_orders } from "@/requests";

export default {
  name: "BulkUpdateOrderStatus",
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    orders: {
      type: Array,
    },
  },
  data() {
    return {
      loading: false,
      selected_status: "",
      ORDER_STATUSES,
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop", "shop_id"]),
    show: {
      get() {
        return !this.disabled;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    updating_claimed_collab_order: function () {
      return this.orders.some(
        (o) => o?.collaboration_status?.status === "claimed"
      );
    },
    updating_shared_order: function () {
      return this.orders.some((o) => o?.is_shared);
    },
    updating_open_collab_order: function () {
      return this.orders.some((o) => o?.collaboration_status?.status === "new");
    },
  },
  methods: {
    async update_orders() {
      this.loading = true;
      const order_ids = this.orders.map((o) => o.id);
      const updates = {
        status: this.selected_status,
      };
      try {
        await update_orders(this.shop_id, order_ids, updates);
        this.$emit("close");
        this.$emit("reset-selected-orders");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.show = false;
      this.selected_status = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.statuses {
  font-weight: 400;
  height: 2.5rem;
}
</style>
