<template>
  <div>
    <span v-if="!collab_status">-</span>
    <v-chip
      v-else-if="collab_status === 'new'"
      class="order-status-chip"
      :outlined="true"
      :class="{ 'red--text': is_expired }"
      :color="is_expired ? null : 'primary darken-2'"
    >
      {{ is_expired ? "Expired" : "Open" }}
      <v-icon
        class="ml-2"
        color="black"
        v-if="!loading"
        small
        @click.stop.prevent="() => close_collaboration_offert(order)"
        >mdi-close</v-icon
      ><v-progress-circular
        class="ml-2"
        v-else
        size="10"
        indeterminate
      ></v-progress-circular>
    </v-chip>
    <v-chip
      :class="{
        'order-status-chip': true,
        'blue-grey lighten-2': collab_status === 'completed',
        'orange darken-2': collab_status === 'returned',
        blue: collab_status === 'claimed',
      }"
      v-else
      class="white--text text-capitalize"
      >{{ collab_status }}</v-chip
    >
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

import { close_collaboration_order } from "@/requests";

export default {
  name: "CollaborationStatusCell",
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(["server_url"]),
    ...mapState("AdminStore", ["shop_id"]),
    collab_status: function () {
      return this.order?.collaboration_status?.status ?? null;
    },
    is_expired: function () {
      return moment().isAfter(moment(this.order.fulfilment_date), "day");
    },
  },
  methods: {
    async close_collaboration_offert(order) {
      this.loading = true;

      try {
        await close_collaboration_order(
          this.shop_id,
          order.id,
          order.collaboration_order_id
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
